<template>
    <div class="landing-page">
        <Banner v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.media && $store.getters.selectedCampaign.media.length > 0"/>
        <div class="landing">
            <!--<router-link :to="{ name: 'select-campaign'}" class="btn login__btn landing__btn">
                <i class="fas fa-arrow-circle-left landing__icon"></i>
                Schimba campania
            </router-link>-->
            <router-link :to="{ name: 'prizes'}" class="btn login__btn landing__btn">
                <i class="fas fa-award landing__icon"></i>
                Vezi premii
            </router-link>
            <router-link :to="{ name: 'add-user' }" class="btn login__btn landing__btn">
                <i class="fas fa-user-plus landing__icon"></i>
                Adauga participant
            </router-link>
            <a href="#" class="btn login__btn landing__btn rules" @click.prevent="showModal()" v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.rule">
              <i class="fas fa-book landing__icon"></i>
              Vezi regulament
            </a>
            <b-modal id="modal-rules" hide-footer class="landing__modal">
                <template slot="modal-title">
                    Regulament campanie
                </template>
                <div class="d-flex h-100" v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.rule">
                     <object
                        :data="$store.getters.selectedCampaign.rule.link"
                        type="application/pdf"
                        width="100%"
                        height="100%"
                        v-if="$store.getters.selectedCampaign && $store.getters.selectedCampaign.rule">
                        <p>Your web browser doesn't have a PDF plugin. Instead you can <a href="filename.pdf">click here to download the PDF file.</a></p>
                        <p>PDF-ul nu poate fi deschis in aceasta fereastra. Puteti da click<a :href="$store.getters.selectedCampaign.rule.link" target="_blank" class="modal__pdf"> aici</a> pentru a deschide PDF-ul intr-o fereastra noua.</p>
                    </object>
                    <pdf
                        v-for="i in numPages"
                        :key="i"
                        :page="i"
                        :src="src"
                    >
                    </pdf>
                </div>
                <div class="landing__no-rules" v-else>Aceasta campanie nu are incarcat un regulament.</div>
                <b-button class="mt-3 modal__btn modal__btn--close" block @click="closeModal()">Inchide</b-button>
            </b-modal>
        </div>
    </div>
</template>

<script>
import Banner from '../../components/Banner'
import CampaignService from '../../api-services/Campaign'
import pdf from 'vue-pdf'
// import store from '../../store/store'

// let loadingTask = pdf.createLoadingTask(`${store.getters.selectedCampaign.rule.link}`)
export default {
  name: 'LandingPage',
  data () {
    return {
      campaign: null,
      src: '',
      numPages: undefined
    }
  },
  components: {
    Banner,
    pdf
  },
  methods: {
    getCampaign () {

      if (!this.$store.getters.selectedCampaign) {
        return this.$router.push({ name: 'select-campaign' })
      } else {
        return new Promise((resolve, reject) => {
          this.campaign = null
          CampaignService.get(this.$store.getters.selectedCampaign.id)
            .then((response) => {
              this.campaign = response.data.data
              resolve(response.data.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
    },
    showModal () {
      this.$bvModal.show('modal-rules')
      if (this.$store.getters.selectedCampaign.rule && this.$store.getters.selectedCampaign.rule.link) {
        this.src = pdf.createLoadingTask(`${this.$store.getters.selectedCampaign.rule.link}`)
      }
    },
    closeModal () {
      this.$bvModal.hide('modal-rules')
    }
  },

  async created () {
    if (
      this.$store.getters.selectedCampaign &&
      this.$store.getters.selectedCampaign.rule &&
      this.$store.getters.selectedCampaign.rule.link
    ) {
      this.src = pdf.createLoadingTask(`${this.$store.getters.selectedCampaign.rule.link}`)

      if (this.src.promise) { // Check if there is a promise property
        this.src.promise.then(pdf => {
          this.numPages = pdf._pdfInfo.numPages
        })
      } else {
        // Handle the case where this.src.promise is not available
        console.error('Loading task does not provide a promise.')
      }
    }

    await this.getCampaign()
    this.$store.dispatch('hideLoading')
  }
}
</script>
